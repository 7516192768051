/** @format */

import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { FunctionComponent as FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { breakpointFrom, breakpointUpTo } from '../../theme/mixins';
import { PartnerCard } from '../../types/page';
import { Icon } from './Icon';
import { Container } from './Layout';
import { RichText } from './RichText';
import { H2, P, Subtitle } from './Typography';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';

interface StyledProps {
  $background: string;
}

export const Logo = styled(Icon)`
  background-size: contain !important;
  background-repeat: no-repeat;
  ${breakpointUpTo.md`
width:60%
  `};
`;

export const WrapperText = styled.div`
  display: grid;
  column-gap: 1.25rem;
  row-gap: 1.75rem;

  ${breakpointFrom.md`
    grid-template-columns: auto minmax(30%, 48rem)
  `};

  ${breakpointUpTo.sm`
    text-align: center;
    margin: 2rem 0;
    align-items:center;
  `};

  h2 {
    color: ${({ theme }) => theme.color.pink};
    grid-column: 1 / -1;
  }

  p {
    color: ${({ theme }) => theme.color.teal.dark};
  }
  p + p {
    font-weight: 400;
  }
`;

export const WrapperCard = styled.div<StyledProps>`
  background-image: url(${({ $background }) => $background});
  background-size: cover;
  justify-content: space-evenly;
  width: 21.75rem;
  height: 22.25rem;
  border-radius: 4.75rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  line-height: 1.4;
  position: relative;

  overflow: hidden;

  &::before {
    content: '';

    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder;
    animation-timing-function: ease;
    border-radius: 1rem;
    position: absolute;
    inset: 0;

    z-index: -1;

    @keyframes placeholder {
      0% {
        background-color: #eeeeee3a;
      }
      50% {
        background-color: #10101039;
      }
      100% {
        background-color: #eeeeee3a;
      }
    }
  }

  p {
    color: ${({ theme }) => theme.color.white};
  }

  ${breakpointUpTo.md`
    width: 18.6875rem;
    height: 14.1875rem;
    border-radius: 1.5625rem;
  `};
`;
export const WrapperServicesMobile = styled(ScrollContainer)`
  padding: 2.75rem 1rem;
  gap: 2rem 1rem;
  align-items: center;
  width: 100%;
  display: flex;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scroll-behavior: initial;
  overflow: scroll;
  flex-flow: row;
  justify-content: flex-start;
  padding: 2.75rem 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WrapperServices = styled.div`
  padding: 2.75rem 1rem;
  gap: 2rem 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-flow: wrap;
`;

export const Title = styled.h1`
  margin-bottom: 2rem;
  text-align: center;
  font-family: ${({ theme }) => theme.font.title};
  color: ${({ theme }) => theme.color.teal.dark};
  font-weight: 100;
  width: 930px;
  ${breakpointUpTo.sm`
  padding: 0 20px;
  margin-bottom: 3rem;
  width: 100%;
  max-width: 100%;
  font-size: ${({ theme }: any) => theme.font.size[45]};
`};
`;
export const Description = styled.h3`
  margin-bottom: 5rem;
  text-align: center;
  font-family: ${({ theme }) => theme.font.title};
  color: ${({ theme }) => theme.color.teal.dark};
  font-weight: 100;
  width: 55%;

  ${breakpointUpTo.sm`
    padding: 0 1.25rem;
    margin-bottom: 3rem;
    width: 100%;
`};
`;

export const WrapperBodyCard = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.font.size[1.125]};
  flex-direction: column;
  p {
    padding: 0 1rem;
  }
`;

export const SectionParnerCard: FC<Props> = ({ text, list }) => {
  const mediaQueryIndex = useMediaQueryIndex();

  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 2), [mediaQueryIndex]);
  const desktop = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex >= 2), [mediaQueryIndex]);
  return (
    <>
      <Container as="section">
        <WrapperText>
          <RichText text={text} customOptions={RichTextCustomOptions} />
        </WrapperText>
        {mobile && (
          <WrapperServicesMobile>
            {list &&
              list.map(({ fields }, index) => {
                return (
                  <WrapperCard key={index} $background={fields.backgroundImage.fields.file.url}>
                    <Logo icon={fields.logo.fields.file.url} width={230} height={80} />
                    <WrapperBodyCard>
                      <RichText text={fields.body} />
                    </WrapperBodyCard>
                  </WrapperCard>
                );
              })}
          </WrapperServicesMobile>
        )}
        {desktop && (
          <WrapperServices>
            {list &&
              list.map(({ fields }, index) => {
                return (
                  <WrapperCard key={index} $background={fields.backgroundImage.fields.file.url}>
                    <Logo icon={fields.logo.fields.file.url} width={230} height={80} />
                    <WrapperBodyCard>
                      <RichText text={fields.body} />
                    </WrapperBodyCard>
                  </WrapperCard>
                );
              })}
          </WrapperServices>
        )}
      </Container>
    </>
  );
};

type Props = {
  text: Document;
  list: Entry<PartnerCard>[];
};

const RichTextCustomOptions: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_5]: (node, children) => <Subtitle as="p">{children}</Subtitle>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
  },
};
